<template>
  <router-view class="mr-2 full-height"></router-view>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'VinculationView',
  components: {
  }
}
</script>

<style scoped>
</style>
